.dim {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal {
  width:91.7949vw;
  padding:8.2051vw 4.1026vw 4.1026vw;
  border-radius: 4.1026vw;
  border:0.2564vw solid #EDEFF2;
  background:#fff;
  text-align: center;
  box-shadow: 0.0000vw 1.0256vw 2.0513vw 0.2564vw #15222B08,0.0000vw 0.2564vw 0.7692vw 0.0000vw #15222B0D;
}

.modalContent {
  position: relative;
}

.modalContent p {
  font-family: 'SpoqaHanSansNeo-Bold', sans-serif;
  color: #30383F;
  font-weight: 700;
  font-size:4.6154vw;
  line-height:5.5385vw;
  letter-spacing:-2.5%;
}

.modalActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:8.2051vw;
}

.modalActions button + button {
  margin-left:2.0513vw;
}

.modalButton {
  flex:1;
  font-family: 'SpoqaHanSansNeo-Bold', sans-serif;
  color: #fff;
  font-weight: 700;
  font-size:4.6154vw;
  line-height:5.5385vw;
  letter-spacing:-2.5%;
  border-radius:2.0513vw;
  background:#EA185E;
}
  
.modalButton.secondary {
  border:0.2564vw solid #EA185E;
  color:#EA185E;
  background: #fff;
}
