/* button */
.fixedBtn {
  z-index:20;
  display:flex;
  position:fixed;
  bottom:0;
  width:100.0000vw;
  padding:4.1026vw;
  box-shadow: 0.0000vw -1.0256vw 2.0513vw 0.2564vw #15222B08, 0.0000vw -0.2564vw 0.7692vw 0.0000vw #15222B0D;
  background-color:#fff;
}

/* .prBtn {
} */

.seBtn {
border:0.2564vw solid #EA185E;
color:#EA185E;
background-color:#fff;
}

.fixedBtn button {
flex:1;
width:inherit;
}

.fixedBtn button  + button {
margin-left:2.0513vw;
}

/* button */