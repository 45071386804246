.dim {
  z-index:1000;
  position:fixed; 
  top:0;
  left:0;
  width:100%;
  height:100vh; 
  display:flex; 
  justify-content:center;
  align-items:center;
  background: (#15222B99, rgba(21, 34, 43, 0.60));
}
/* .loadingContainer {
  
} */
.loadingText {
  margin-top:2.0513vw;
  color:#fff;
  font-size: 4.6154vw;
  font-weight: 700;
  line-height: 120%; 
  text-align:center;
  letter-spacing: -0.1154vw;
}