/* button  - 옮길예정*/
.fixedBtn {
  z-index:20;
  display:flex;
  position:fixed;
  bottom:0;
  left:0;
  width:100.0000vw;
  padding:4.1026vw;
  box-shadow: 0.0000vw -1.0256vw 2.0513vw 0.2564vw #15222B08, 0.0000vw -0.2564vw 0.7692vw 0.0000vw #15222B0D;
  background-color:#fff;
}

button {
  font-weight: 700;
  width:100%;
  height:12.3077vw;
  padding:0 4.1026vw;
  border-radius:2.0513vw;
  color:#FBFBFB;
  font-size:4.6154vw;
  line-height:12.3077vw;
  letter-spacing: -0.1154vw;
  text-overflow: ellipsis;
  white-space:nowrap;
  overflow:hidden;
  background:#EA185E;
}

/* .prBtn {
} */

.seBtn {
  border:0.2564vw solid #EA185E;
  color:#EA185E;
  background-color:#fff;
}

.fixedBtn button {
  flex:1;
  width:inherit;
}

.fixedBtn button  + button {
  margin-left:2.0513vw;
}

button:disabled {
  color:#AEB4BA;
  background-color:#EDEFF2;
}

/* button */
/* reset */
/* * {
  margin:0;
  padding:0;
  box-sizing:border-box;
} */
html, body, ul, li, ol, dl, dt, dd, form, fieldset, legend, label, input, section, article, aside, span, p, 
nav, h1, h2, h3, h4, h5, h6, img, figure, figcaption, table, colgroup, thead, tbody, th, tr, td,select, option, div, time, caption, a, header, main, footer{margin:0;padding:0;box-sizing:border-box;}
header,footer,section,article,aside,nav{
  margin:0;
  padding:0;
  box-sizing:border-box;
  display:block;
}

a {
  text-decoration:none;
  color:#606870;
}

ol, ul {
  list-style:none;
}

button {
  border:none;
}

input {
  font-family: "Spoqa Han Sans Neo";
  font-size: 4.1026vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.1026vw;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color:#fff;
  caret-color:#00BFD9;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}

input::-ms-clear { 
  display: none; 
}

input[type="checkbox"],
input[type="radio"] {
    border:0;
    box-sizing: border-box; 
}

input[type="text"] {
  width:100%;
  font-size:4.1026vw;
}

input[type="tel"] {
  background-color: #ffffff !important;
  box-shadow: none; 
}

input:focus {
  border:0.2564vw solid #484E54;
}

input:disabled , 
input[type="tel"]:disabled {
  color:#939BA3;
  border:0.2564vw solid #DBDFE2;
  background-color:#EDEFF2 !important;
}

label {
  display:flex;
  width:91.7949vw;
  padding:2.0513vw;
  align-items:center;
  font-weight:700;
  font-size: 4.6154vw;
  line-height: 120%;
  letter-spacing: -0.1154vw;
}

input, select {
  width: 100%;
  padding:3.5897vw;
  border-radius:2.0513vw;
  border:0.2564vw solid #DBDFE2;
  outline: none;
}

select {
  font-family: "Spoqa Han Sans Neo";
  font-size: 4.1026vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.1026vw;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color:#484E54;
}

select::-ms-expand {
  display: none; 
}

select option {
  color:#484E54;
}

/* common */
@font-face {
  font-family: 'SpoqaHanSansNeo-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../../../public/fonts/SpoqaHanSansNeo-Bold.ttf') format('truetype'),
  url('../../../public/fonts/SpoqaHanSansNeo-Bold.woff') format('woff'),
  url('../../../public/fonts/SpoqaHanSansNeo-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'SpoqaHanSansNeo-Medium';
  font-style: normal;
  font-weight: 500;
  src: url('../../../public/fonts/SpoqaHanSansNeo-Medium.ttf') format('truetype'),
  url('../../../public/fonts/SpoqaHanSansNeo-Medium.woff') format('woff'),
  url('../../../public/fonts/SpoqaHanSansNeo-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'SpoqaHanSansNeo-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../../../public/fonts/SpoqaHanSansNeo-Regular.ttf') format('truetype'),
  url('../../../public/fonts/SpoqaHanSansNeo-Regular.woff') format('woff'),
  url('../../../public/fonts/SpoqaHanSansNeo-Regular.woff2') format('woff2');
}

/* forms */
.formGroup {
  margin-top:10.2564vw;
  overflow:hidden;
}


.phoneInputWrapper {
  position:relative;
}

.phoneInputWrapper .formGroup + .formGroup {
  margin-top:2.0513vw;
}

.phoneInputWrapper .prBtn {
  font-family: 'SpoqaHanSansNeo-Bold';
  padding: 2.0513vw 4.1026vw;
  font-size: 4.1026vw;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.1026vw;
}

.verifiWrap {
  position:relative;
}

.verifiWrap .timer {
  position:absolute;
  top:13.5897vw;
  right:14.8718vw;
  color:#939BA3;
  font-size:4.1026vw;
  font-weight:400;
}



/* forms */

/* button {
  height:12.3077vw;
  padding:0 4.1026vw;
  border-radius:2.0513vw;
  color:#FBFBFB;
  line-height:12.3077vw;
  background:#EA185E;
}

button:disabled {
  color:#AEB4BA;
  background:#EDEFF2;
} */

.waringWrap .warningMessage {
  margin-top: 2.0513vw;
  color: #FC5B00;
}

.snackbarWrap {
  z-index:100;
  position:absolute;
  bottom:0;
  left:50%;
  transform:translateX(-50%);
}

.snackbar {
  width:91.7949vw;
  padding:4.1026vw 6.1538vw;
  border-radius:2.0513vw;
  color:#FBFBFB;
  font-size:4.1026vw;
  text-align:center;
  background:#484E54;
}

.f12 {
  font-size:3.0769vw;
}

.f14 {
  font-size:3.5897vw;
}

.f16 {
  font-size:4.1026vw;
}

body {
  font-family: 'SpoqaHanSansNeo-Regular';
  font-size:3.5897vw;
  font-weight: 400;
  line-height: 120%; 
  letter-spacing: -0.0769vw;
  overflow:hidden;
}

/* box-shadow: offset-x offset-y blur-radius spread-radius color; */
/* layout */
.headerWrap {
  z-index:30;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100.0000vw; 
  height: 12.3077vw;
  box-shadow: 0.0000vw 0.2564vw 0.7692vw 0.0000vw rgba(21, 34, 43, 0.05), 0.0000vw 1.0256vw 2.0513vw 0.2564vw rgba(21, 34, 43, 0.03);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background:#fff;
}

.logoWrap {
  width:100%;
  /* height:11.2821vw; */
}
/* .headerWrap .hIcon {
  width:12.3077vw;
  height:12.3077vw;
} */

.iconWrap {
  width:12.3077vw;
  height:12.3077vw;
}
.iconWrap img {
  width:100%;
  height:100%;
}

.headerWrap .title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width:50.7692vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 4.6154vw;
  text-align:center;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.1154vw;
}

.headerWrap .icons {
  display: flex;
  align-items: center;
}

main {
  margin:12.3077vw auto 0;
  height:calc(100vh - 46.1538vw);
  overflow-y:scroll;
}

.footerWrap {
  position:fixed;
  bottom:0;
  width:100.0000vw;
}

.footerWrap .footerLinks {
  display: flex;
  padding:4.1026vw;
  align-items: center;
}

.footerWrap .footerLinks a {
  font-family: 'SpoqaHanSansNeo-Regular';
  position:relative;
  flex:content;
  padding: 0 4.1026vw;
  font-size:3.0769vw;
}

.footerWrap .footerLinks a + a {
  margin-left:4.1026vw;
}

.footerWrap .footerLinks a + a:before {
  content:'';
  position:absolute;
  top:50%;
  left:-2.0513vw;
  transform:translateY(-50%);
  width:0.2564vw;
  height:4.1026vw;
  background:#DBDFE2;
}

.footerSection {
  padding: 3.0769vw;  
  color:#606870;
  font-size:3.0769vw; 
  font-weight:400;
  line-height:120%;
  letter-spacing:-0.0769vw;
}

.footerSection + .footerSection {
  border-top:0.2564vw solid #EDEFF2;
}

.footerSection .bold {
  font-family: 'SpoqaHanSansNeo-Medium';
  color:#30383F;
  font-size:3.0769vw;
  font-weight:500; 
  line-height:120%;
  letter-spacing:-0.0641vw;
}

.footerSection .bold.big {
  font-family: 'SpoqaHanSansNeo-Bold';
  font-weight:700;
  font-size:4.1026vw;
}

.footerSection .bold.f14 {
  font-family: 'SpoqaHanSansNeo-Bold';
  font-weight:700;
  font-size:3.5897vw;
}

.footerWrap .subSec {
  margin-top:2.0513vw;
}

.footerWrap .subSec p + p {
  margin-top:1.0256vw;
}

.footerSection:last-child .subSec p + p{
  margin-top:2.0513vw;
}

/* auth */
.auth {
  height:100vh;
  margin:0;
}

.authWrap {
  position:relative;
  width:100.0000vw;
  height:123.0769vw;
  margin:51.7949vw auto 0;
}

.logo{
  width:61.5385vw;
  height:22.5641vw;
  margin: 0 auto;
  text-align:center;
}

.logo svg {
  width:61.5385vw;
  height:22.5641vw;
}

.btnWrap {
  margin:18.4615vw 4.1026vw 0;
}

.btnWrap button + button {
  margin-top:4.1026vw;
}

.snsLogin {
  display:flex;
  align-items: center;
  width:91.7949vw;
  height:11.2821vw;
  padding-left:6.1538vw;
  border-radius:3.0769vw;
}

.snsLogin svg {
  width:8.2051vw; 
  height:8.2051vw; 
}

.snsLogin.kakao {
  background:#FDE500;
}
.snsLogin.kakao p {
  color:#000;
}
.snsLogin.naver {
  background:#03C75A;
}
.snsLogin.naver p {
  color:#fff;
}
.snsLogin.google {
  border:0.2564vw solid #747775;
  background:#fff;
}
.snsLogin.google p {
  color:#000;
}
.snsLogin.apple {
  background:#15222B;
}
.snsLogin.apple p {
  color:#fff;
}
.snsLogin p {
  width:69.7436vw;
  font-size:3.5897vw;
  font-weight: 400;
  line-height: 120%; 
  letter-spacing: -0.0897vw;
}

.divideWrap {
  display:flex;
  justify-content: space-between;
  width: 91.7949vw;
  margin: 17.2051vw 4.1026vw 0;
}

.divideWrap a {
  font-family: 'SpoqaHanSansNeo-Medium';
  display:block;
  width: 91.7949vw;
  height: 56.4103vw;
  padding: 4.0513vw 4.1026vw;
  color:#78828C;
  font-size: 4.1026vw;
  font-weight: 500;
  line-height: 46.9231vw;
  letter-spacing: -0.1026vw;
  text-align: center;
  border:0.2564vw solid #DBDFE2;
  border-radius:2.0513vw;
}

.divideWrap a + a {
  margin-left:5.1282vw;
}

.recentlyLogin {
  position:absolute;
  top:24.6154vw;
  left:50%;
  transform:translateX(-50%);
  width:73.8462vw;
  max-width:auto;
  padding: 2.0513vw 4.1026vw 2.0513vw 2.0513vw;
  border-radius:25.3846vw;
  background:#EDEFF2;
}

.reWrap {
  display: flex;
  align-items: center;
}

.recentlyLogin svg {
  width:8.2051vw; 
  height:8.2051vw; 
}

.recentlyLogin span {
  display:inline-block;
  margin-left:3.8462vw;
  color:#78828C;
  font-size: 3.5897vw;
  font-weight: 400;
  letter-spacing: -0.0897vw;
}

.recentlyLogin strong {
  font-family: 'SpoqaHanSansNeo-Bold';
  color:#30383F;
  font-weight: 700;
}



/* auth */

.dim {
  z-index:10;
  position:fixed;
  top:0;
  left:0;
  width:100.0000vw;
  height:100vh;
  background:rgba(21,34,43,0.6);
}

.menu {
  z-index:99;
  position:fixed;
  top: 0;
  left: 0;
  right: 0;
  width:91.7949vw;
  height:100vh;
  background:#fff;
  overflow-y:scroll;
}

.menu .top {
  position:relative;
  width:100%;
  height:14.3590vw;
}

.menu .top .close {
  position:absolute;
  left:0;
  width:14.3590vw;
  height:14.3590vw;
} 

.menu .top .search {
  position:absolute;
  right:0;
  width:14.3590vw;
  height:14.3590vw;
} 

.menu .menuList li a {
  display:inline-flex;
  align-items:center;
  justify-content:space-between;
  width:91.7949vw;
  padding: 0 4.1026vw;
  color:#30383F;
  font-size: 4.1026vw;
  letter-spacing: -0.1026vw;
}

.menu .menuList li {
  border-bottom:0.2564vw solid #EDEFF2;
}

.menu .loginBtn {
  font-family: 'SpoqaHanSansNeo-Bold';
  display:block;
  width:83.5897vw;
  height:12.3077vw;
  margin:8.2051vw auto 0;
  border-radius:2.0513vw;
  font-size: 4.6154vw;
  line-height:12.3077vw;
  letter-spacing: -0.1154vw;
  text-align:center;
  color:#fff;
  background:#EA185E;
}

.menuFooter {
  margin-top:17.9487vw;
}

.menuFooter .kakao_chanel {
  display:flex;
  align-items:center;
  margin-top:2.0513vw;
}

.menuFooter .kakao_chanel span {
  font-family: 'SpoqaHanSansNeo-Bold';
  display:inline-block; 
  margin-left: 1.9231vw;
  color:#30383F;
  font-weight:700;
  letter-spacing: -0.0897vw;
}

.menuFooter .subSec {
  margin-top:2.0513vw;
}

.menuFooter .subSec p + p {
  margin-top:1.0256vw;
}


form .fixedBtn {
  position:fixed;
  /* bottom:4.1026vw; */
  bottom: env(safe-area-inset-bottom, 4.1026vw);
  left:0;
  width: 100%;
  transition: bottom 0.3s ease;
}

form .fixedBtn.keyboard-up {
  /* height:calc(100vh - 40vh); */
  bottom: calc(4.1026vw + 51.2821vw);
}

.customInput, .customSelect {
  width: 100%;
  padding:3.5897vw;
  border:0.2564vw solid #DBDFE2;
  border-radius:2.0513vw;
  box-sizing: border-box;
}

.selectWrap {
  position:relative;
}

.selectWrap .arrowIcon  {
  position:absolute;
  top:50%;
  right:3.5897vw;
  margin-top:-3.3333vw;
  background:url('../../../public/icons/arrow_select_icon.svg') no-repeat;
  background-size:6.1538vw;
  transform:rotate(0deg);
  transition:transform 0.3s;
}

.selectWrap .arrowIcon svg {
  width:6.1538vw;
  height:6.1538vw;
}

.selectWrap.open .arrowIcon {
  transform:rotate(180deg);
}

.customInput.inputError, .customSelect.selectError {
  border-color:#FC5B00;
}

.errorMessage {
  display:block;
  color:#FC5B00;
  font-size: 3.0769vw;
  margin-top: 2.0513vw;
}

#verificationCode {
  margin-top:-6.1538vw;
}

.verificationSection {
  position:absolute;
  top:31.7949vw;
  right:0;
}

.loginWrap .logo {
  margin-top:33.3333vw;
}

.inputWrap {
  position:relative;
}

.errorIcon {
  position:absolute;
  top:1.9487vw;
  right:3.5897vw;
  width:8.2051vw;
  height:8.2051vw;
}

.phoneWrap .formGroup:nth-child(2){
  width:56.9231vw;
}
/* page */
.page {
  padding:0 4.1026vw;
}

/* agreement */
.agreementWrap {
   margin-top:26.1538vw;
}

.agreementWrap h1 {
  font-family: 'SpoqaHanSansNeo-Bold';
  color:#30383F;
  font-weight: 700;
  font-size: 6.1538vw;
  line-height: 135%;
  letter-spacing: -0.1538vw;
}

.agreementWrap .agreeList {
  margin-top:10.2564vw;
}

.agreementWrap .agreeList li:first-child {
  color:#30383F;
  font-size:4.6154vw;
  font-weight: 700;
  border-radius:3.0769vw;
  background-color:#EDEFF2;
}

.agreementWrap .agreeList li + li {
  margin-top:2.0513vw;
  border-bottom:0.2564vw solid #EDEFF2;
}

.agreementWrap .agreeList li {
  color:#78828C;
  font-size:4.1026vw;
  font-weight:300;
}

.agreementDetail {
  padding:0;
  height:inherit;
}

/* modal */
.modalLottie {
  height: 12.3077vw;
  width: 12.3077vw;
  margin: 0 auto 4.1026vw;
}